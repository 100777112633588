@import "_normalize";
@import "_variables";
@import "_helpers";
@import "_flexbox";
@import "_buttons";
@import "_typography";
@import "_admin";

@import "hub_widget";
@import "hub_widget+responsive";

@import "scheduling_tool+responsive";

/*------------------------------------*\
              ¡GENERAL
\*------------------------------------*/
* {
  box-sizing: border-box;
}
body,
html {
  height: 100vh;
  margin: 0;
}
.container-wrapper {
  @include flexbox();
  @include justify-content(center);
  height: 100vh;
}
.container {
  .mostly-full {
    height: calc(100% - 160px);
  }
  @include flexbox();
  @include flex-direction(column);
  width: 100%;
  max-width: 1280px;
  max-height: 100%;
  height: 100%;
  &-full-width {
    max-width: 100%;
    align-items: center;
  }
}
.container-full {
  .mostly-full {
    height: calc(100% - 160px);
  }
  @include flexbox();
  @include flex-direction(column);
  width: 100%;
  max-height: 100%;
  height: 100%;
}
.flex {
  @include flexbox();
  @include align-items(center);
}
.flex-column {
  @include flexbox();
  @include flex-direction(column);
}
.top {
  @include flexbox();
  margin-bottom: auto;
}
.bottom {
  @include flexbox();
  margin-top: auto;
}
.bottom-fixed {
  position: fixed;
  bottom: 40px;
  left: 0;
  right: 0;
}
.left {
  @include flexbox();
  margin-right: auto;
}
.right {
  @include flexbox();
  margin-left: auto;
}
.clickable {
  cursor: pointer;
}

/*------------------------------------*\
              ¡HEADER
\*------------------------------------*/

.header-row {
  @include flexbox();
  @include align-items(center);
  @include flex-direction(row);
  @include justify-content(flex-end);
  width: 100%;
  min-height: 120px;
  .header {
    @include flexbox();
  }

  .profile-wrapper {
    @include flexbox();
    @include align-items(center);
  }

  .profile-image {
    @include flexbox();
    @include flex-direction(column);
    @include align-items(center);
    overflow: hidden;
    text-align: center;
    img {
      height: auto;
      width: 80px;
    }
    span {
      padding-top: 5px;
      text-transform: uppercase;
      font-size: 0.8em;
    }
  }

  .profile-name {
    @include flexbox();
  }

  .image-link {
    &-active {
      box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.4);
    }
    &:hover {
      box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.4);
    }
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    @include flexbox();
    @include align-items(center);
    @include flexbox();
    @include flex-direction(column);
    @include align-items(center);
    text-align: center;
    padding: 10px;
    width: 100px;
    img {
      height: auto;
      width: 35px;
    }
    span {
      padding-top: 5px;
      text-transform: uppercase;
      font-size: 0.8em;
    }
  }

  .back-link {
    display: inline-block;
    padding: 10px 10px 10px 20px;
    font-weight: normal;
    font-size: 20px;
    margin-top: 50px;
    margin-left: 20px;
    background-image: url("/img/leftarrowblack.png");
    background-repeat: no-repeat;
    background-size: 15px;
    background-position: left center;
  }
  .nav-links {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 40%;
    padding-top: 50px;
    padding-right: 30px;
    @media only screen and (max-width: 620px) {
      flex-direction: column;
    }
    .nav-link {
      display: inline-block;
      font-weight: bold;
      font-size: 20px;
    }
  }

  .active {
    color: $yellow;
  }
}

/*------------------------------------*\
              ¡TABS
\*------------------------------------*/

.tabs-row {
  @include flexbox();
  width: 100%;
  min-height: 35px;
  .tabs-right {
    margin-left: 30%;
    width: 100%;
  }
  .tabs {
    @include flexbox();
    @include flex-direction(row);
    // margin-left: 30%;
    .tab {
      @include flexbox();
      @include align-items(center);
      @include justify-content(flex-start);
      max-width: 200px;
      width: 100%;
      height: 35px;
      background-color: $light-gray;
      border-top-right-radius: 12px;
      border-top-left-radius: 12px;
      padding-left: 20px;
      padding-right: 20px;
      font-weight: 600;
    }
    .active {
      background-color: $off-white;
    }
  }
}

/*------------------------------------*\
              ¡LARGE CALENDAR
\*------------------------------------*/
.large-calendar {
  height: 100%;
  position: relative;
  flex-grow: 1;
  margin-bottom: auto;
  margin-top: 25px;
  padding-bottom: 25px;

  .row {
    @include flexbox();
    @include flex-direction(row);
    @include justify-content(space-between);
    margin-bottom: 0;
    &-header {
      height: 35px;
    }
    &-days {
      border-bottom: 1px solid #a4a4a4;
    }
    .back-left {
      display: flex;
      position: absolute;
      title {
        font-size: 14px;
      }
    }
  }
  title {
    font-weight: bold;
    font-size: 22px;
  }
  .day {
    // height: 100%;
    width: 70%;
    min-width: 100px;
    font-weight: 200;
    padding: 10px;
    border-left: 1px solid #a4a4a4;
    &:last-of-type {
      border-right: 1px solid #a4a4a4;
    }
    background-color: #f5f5f5;
    &-label {
      height: 35px;
      line-height: 35px;
      text-align: center;
      padding: 0;
      background-color: #5c5c5c;
      color: #ffffff;
    }
    .truck-name {
      width: 100%;
      height: 20px;
      line-height: 15px;
      font-size: 12px;
      background-color: $yellow;
      color: $white;
      border-radius: 25px;
      padding: 2px;
      padding-left: 15px;
      padding-right: 15px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
      margin-bottom: 5px;
      &-none {
        background-color: $white;
        color: #000000;
        border: 2px solid $yellow;
      }
    }
  }
}

.filter-large {
  // margin-top: auto;
  height: 100%;
  min-width: 200px;
  img {
    width: auto;
    height: 100px;
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .filter-item {
    margin-bottom: 10px;
    .filter-indicator {
      cursor: pointer;
      height: 15px;
      width: 15px;
      border: 1px solid black;
      display: inline-block;
      margin-right: 10px;
      &.selected {
        background-color: $yellow;
      }
    }
  }
  .bottom {
    display: block;
    margin-top: auto;
    .book-button {
      display: block;
      margin-top: 10px;
      width: 100%;
    }
  }
}

/*------------------------------------*\
              ¡CALENDAR
\*------------------------------------*/

.calendar-row {
  @include flexbox();
  height: 40%;
  min-height: 370px;
  width: 100%;

  .calendar {
    @include flexbox();
    @include flex-direction(column);
    background-color: $dark-gray;
    font-size: 0.8em;
    width: 30%;
    color: white;
    padding-bottom: 10px;

    title {
      font-size: 1.25em;
    }

    .logo-wrapper {
      @include flexbox();
      @include justify-content(center);
      height: 40px;

      .logo {
        position: relative;
        top: -120px;
        height: 150px;
        width: auto;
      }
    }

    .row {
      @include flexbox();
      @include flex-direction(row);
      @include justify-content(space-between);
      margin-top: 4px;
      margin-bottom: 4px;
    }

    .day {
      text-align: center;
      height: 30px;
      width: 30px;
      font-weight: 200;
      border-radius: 100%;
      padding-top: 7px;
      padding-bottom: 3px;
      &.has-events {
        background-color: $yellow;
        &-booked {
          background-color: $green;
        }
        &-invalid {
          background-color: $very-dark-gray;
        }
      }
    }
  }
  .week {
    @include flexbox();
    @include flex-direction(row);
    @include justify-content(space-between);
    width: 70%;
    overflow-x: auto;

    .day {
      width: 100%;
      -webkit-transition: width 0.4s;
      transition: width 0.4s;
      border-right: 1px solid $light-gray;
      background-color: $off-white;
      .header {
        @include flexbox();
        @include flex-direction(column);
        height: 100%;
        .event-holder {
          overflow-y: auto;
        }
        .number,
        .title {
          text-align: center;
        }

        .number {
          font-size: 3em;
        }
        .title {
          padding-top: 5px;
          text-transform: uppercase;
        }
        .event {
          @include flexbox();
          @include flex-direction(wrap);
          @include justify-content(center);
          text-align: center;
          padding: 8px 5px;
          margin: 5px;
          border-radius: 15px;
          background-color: $yellow;
          &-booked {
            background-color: $green;
          }
          &-invalid {
            background-color: $dark-gray;
          }
          .time {
            display: none;
          }
          .name {
          }
        }
        .event:hover {
          cursor: pointer;
        }
      }
    }
    .day:hover {
      width: 135%;
      .time {
        display: unset !important;
      }
    }
  }
}

/*------------------------------------*\
              ¡FILTER
\*------------------------------------*/

.filter-row {
  @include flex(1);
  @include flexbox();
  height: 50%;
  min-height: 400px;
  width: 100%;
  &-sub {
    flex-direction: row;
    width: 100%;
    @include flex(1);
    @include flexbox();
  }
  .filter {
    @include flexbox();
    @include flex-direction(column);
    width: 30%;
    border-bottom: 1px solid $light-gray;
    border-left: 1px solid $light-gray;
    height: 100%;

    &-search {
      height: 50px;
      margin: 0;
      border: 0;
      border-bottom: 1px solid $light-gray;
      background-image: url("/img/searchicon.svg");
      background-repeat: no-repeat;
      background-position: 4% 50%;
      background-size: 20px;
      text-indent: 35px;
      font-size: 16px;
    }

    .scroll-wrapper {
      overflow-y: scroll;
    }

    .filter-item {
      @include flexbox();
      @include flex-direction(row);
      @include justify-content(space-between);
      @include align-items(center);
      max-height: 70px;
      min-height: 70px;
      border-bottom: 1px solid $light-gray;

      .left {
        width: 90%;
      }

      .plus {
        height: 34px;
        width: 34px;
        background-image: url("/img/Plus@2x.png");
        background-size: cover;
        cursor: pointer;
      }
      .minus {
        height: 34px;
        width: 34px;
        background-image: url("/img/Minus@2x.png");
        background-size: cover;
        cursor: pointer;
      }
    }

    .button-wrapper {
      width: 50%;
      margin: 5px;
    }

    .book-button {
      margin-top: 5px;
    }

    .bottom {
      @include align-items(center);
      min-height: 70px;
    }
  }
  .event-detail {
    @include flexbox();
    @include flex-direction(column);
    width: 70%;
    border-bottom: 1px solid $light-gray;
    background-color: $off-white;

    &-info {
      @include flexbox();
      @include flex-direction(row);
    }
    &-column {
      width: 50%;
    }
    .truck-list {
      max-height: calc(100% - 25px);
      overflow: auto;
    }
  }
  .rule {
    width: 30%;
    background-color: $light-gray;
    &-subtitle {
      font-size: 1.3em;
      font-weight: 500;
    }
  }
  .header {
    @include flexbox();
    @include align-items(center);
    height: 75px;
    background-color: $dark-gray;
    color: white;
    .title {
      font-size: 2em;
    }
  }
}

.table-holder {
  max-height: calc(100% - 75px);
  overflow-y: auto;
  .event-table {
    width: 100%;
    border-collapse: collapse;
    tr {
      &:nth-child(odd) {
        background-color: white;
      }
      &.head {
        background-color: $green;
        color: $white;
      }
    }
    td {
      padding: 15px;
    }
  }
}

/*------------------------------------*\
                ¡AUTH
\*------------------------------------*/

.login-container {
  width: 350px;
  height: 550px;
  margin: 5vh auto;
  text-align: center;
  & textarea {
    padding: 5px;
    width: 95%;
  }
  & label {
    display: none;
  }
}

.message {
  text-align: center;
  color: #3c763d;
  font-family: "Raleway", sans-serif;
  width: 100%;
  font-size: 20px;
  padding: 20px;
  background-color: #dff0d8;
}

.fill-1third-height {
  height: 33.33%;
}
.fill-2third-height {
  height: 66.66%;
}
.fill-half {
  height: 50%;
}

.grid-20 {
  display: grid;
  grid-gap: 20px;
}

input[type="text"],
input[type="password"],
input[type="email"] {
  width: 100%;
  height: 36px;
  line-height: 30px;
  font-size: 12px;
  margin: 5px 0;
  padding: 1px 1px 1px 10px;
  margin-bottom: 1rem;
}

textarea,
input[type="file"] {
  width: 100%;
  margin-bottom: 1rem;
  padding: 0.5rem;
}

input[type="submit"] {
  margin-top: 1rem;
}

input[type="checkbox"] {
  height: 16px;
  width: 16px;
  margin: 4px;
}

a {
  text-decoration: none;
  color: black;
}

.errorlist {
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 0;
}

/*------------------------------------*\
            ¡EVENT DETAIL
\*------------------------------------*/

.solid-button {
  color: $white;
  background-color: $yellow;
}

.event-title {
  font-size: 1.3em;
  font-weight: 500;
}

.event-address {
  margin-top: 15px;
}

/*------------------------------------*\
            ¡PROFILE EDIT
\*------------------------------------*/
.profile-message-container {
  width: 350px;
  height: 550px;
  text-align: center;
  margin: 5px auto;
}

.profile-container {
  width: 100%;
  padding-top: 100px;
  height: calc(100% - 220px);
  .title {
    text-align: center;
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    margin-bottom: 15px;
  }
}

.edit-profile {
  margin: auto;
  width: 30%;
  font-family: "Raleway", sans-serif;
  font-size: 0.75em;
  padding-bottom: 5rem;
  .login-button {
    width: 100%;
  }
  .form-group {
    margin-top: 1rem;
  }
  textarea {
    font-family: inherit;
  }
  input[type="file"] {
    margin-bottom: unset;
  }
  label {
    display: block;
    &.inline {
      display: inline-block;
    }
  }
}

.errorlist {
  font-weight: 700;
  color: #cc0000;
}

/*------------------------------------*\
              ¡MODAL
\*------------------------------------*/

.modal-background {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  z-index: 15;
  transition: opacity 0.2s ease-out;
  &.hidden {
    opacity: 0;
    pointer-events: none;
  }
}
.modal-container {
  padding: 10px;
  border: 1px solid black;
  position: absolute;
  @include transform(translate(-50%, -50%));
  top: 50%;
  left: 50%;
  background-color: white;
  min-width: 350px;
  max-height: 75%;
  overflow-y: auto;
}
.book-event-modal {
  @include flexbox();
  @include flex-direction(column);
  @include align-items(center);

  .header {
    @include flexbox();
    @include flex-direction(row);
    margin-top: -20px;
    h3 {
      margin: 0;
    }
  }
  .event-item {
    width: 100%;
    // height: 20px;
    // line-height: 15px;
    // font-size: 12px;
    background-color: $yellow;
    color: $white;
    border-radius: 25px;
    padding: 2px;
    padding-left: 15px;
    padding-right: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    margin-bottom: 5px;
    &-none {
      background-color: $white;
      color: $yellow;
      border: 2px solid $yellow;
    }
  }
  .truck-select {
    .select-arrow {
      position: absolute;
      background-image: url("/img/downarrow.svg");
      background-repeat: no-repeat;
      right: 6px;
      top: 6px;
      pointer-events: none;
      width: 20px;
      height: 20px;
    }
    position: relative;
    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      &::-ms-expand {
        display: none;
      }
      background-color: $off-white;
      border-radius: 4px;
      border: 1px solid $dark-gray;
      font-size: 16px;
      height: 25px;
      margin-top: 3px;
      outline: 0;
      padding: 2px 5px 2px 8px;
      width: 100%;
    }
  }
  .button-container {
    @include flexbox();
    @include flex-direction(row);
    @include justify-content(space-between);
    @include align-items(center);
    width: 90%;
    .modal-button {
      margin: 5px;
      width: 45%;
    }
  }
  .book-truck-container {
    @include flexbox();
    @include flex-direction(column);
    @include justify-content(space-between);
    @include align-items(center);
    width: 80%;
    button {
      width: 45%;
    }
  }
}

/*------------------------------------*\
              ¡MODAL
\*------------------------------------*/
.react-spinner-wrapper {
  @include flexbox();
  @include align-items(center);
  min-height: 260px;
  &.black {
    .react-spinner_bar {
      background-color: black;
    }
  }
}
.react-spinner {
  position: relative;
  width: 40px;
  height: 40px;
  top: 50%;
  left: 50%;
}

.react-spinner_bar {
  -webkit-animation: react-spinner_spin 1.2s linear infinite;
  -moz-animation: react-spinner_spin 1.2s linear infinite;
  animation: react-spinner_spin 1.2s linear infinite;
  border-radius: 5px;
  background-color: white;
  position: absolute;
  width: 20%;
  height: 7.8%;
  top: -3.9%;
  left: -10%;
}

@keyframes react-spinner_spin {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.15;
  }
}

@-moz-keyframes react-spinner_spin {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.15;
  }
}

@-webkit-keyframes react-spinner_spin {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.15;
  }
}

.container-notifications {
  .subheader {
    font-size: 20px;
    font-weight: bold;
    &-intermediate {
      width: 75%;
      margin: auto;
    }
  }

  .header-img {
    width: 140px;
    height: auto;
    position: absolute;
    left: 50%;
    margin-left: -70px;
  }
}

/*------------------------------------*\
              ¡CONTACT INFO
\*------------------------------------*/

.contact-info {
  width: 65%;
  margin: 10px auto 25px auto;

  .half {
    width: 50%;
    margin: 0px;
    padding: 0;
    display: inline-block;
  }

  .description {
    color: gray;
    font-size: 14px;
    padding-top: 20px;
  }

  .contact-input {
    width: 70%;
    margin-right: 25px;
    font-family: "Raleway", sans-serif;
    font-size: 16px;
    &-invalid {
      border: 1px solid $red;
    }
  }
  .add {
    background-color: $yellow;
    color: white;
    text-align: center;
    border-radius: 30px;
    height: 30px;
    line-height: 30px;
    width: 20%;
    display: inline-block;
    cursor: pointer;
  }

  .contact-list {
    .contact {
      position: relative;
      margin-bottom: 10px;
    }
    .remove {
      position: absolute;
      right: 0;
      cursor: pointer;
      color: $red;
      font-size: 30px;
      line-height: 18px;
    }
  }
}

.overlay {
  z-index: 10;
  display: block;
  position: fixed;
  height: auto;
  width: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  text-align: center;
  overflow: auto;
}
.overlay-message {
  margin: auto;
  height: 50%;
  width: 50%;
  padding: 30vh 0;
  color: white;
  font-size: 36px;
}

/*------------------------------------*\
              ¡DASHBOARD
\*------------------------------------*/
.dashboard-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
// CSS for elevated card view
.img-button-wrapper {
  &:hover {
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.4);
  }
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  width: 100%;
  margin: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    width: 80px;
    height: auto;
  }
}
