.hub-widget {
  width: 1px;
  min-width: 100%;
  font-family: Raleway;
}

.widget-head,
.widget-subheading {
  width: 100%;
  background-color: #FED624;
}

.widget-head {
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  padding-top: 10px;
}

.widget-subheading {
  height: 50px;
  position: relative;
  box-shadow: 0 3px 3px 0px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  .subhead-content {
    height: 50px;
    line-height: 50px;
    font-size: 18px;
    width: 50%;
    display: block;
    .arrow {
      height: 15px;
      width: auto;
      margin-top: 17px;
      margin-left: 15px;
      &-left {
        margin-left: 0;
        margin-right: 15px;
        transform: rotate(180deg);
      }
    }
    &.left {
      top: 0;
      left: 0;
      position: absolute;
      cursor: pointer;
      padding-left: 10px;
      padding-right: 10px;
    }
    &.right {
      text-align: right;
      top: 0;
      right: 0;
      position: absolute;
      cursor: pointer;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.week-container {
  display: flex;
  // flex-flow: row wrap;
  // flex-wrap: wrap;
  // justify-content: flex-start;
  // flex-direction: column;
  align-items: flex-start;
  background-image: url('/img/week-background.png');
  background-repeat: repeat-x;
  
  .endCalendar {
    border-right: 1px solid black;
  }

  .widget-day {
  position: relative;
  flex: 1;
  height: auto;
  .content {
    display: flex;
    width: 100%;
    min-height: 100%;
    }
  text-align: left;
  color: black;
  transition: .5s;
  max-width: 20%;
  min-width: 1%;
  background-color: transparent;
  padding: 10px;
  height: 380px;
  border-bottom: 1px solid black;
  border-left: 1px solid black;
  
  .date-label {
      height: 100%;
      width: 150px;
      position: absolute;
      left: 0;
      .day-name {
        text-align: top;
      }
  }


  .time {
    font-size: 18px;
    text-align: left;
    margin-bottom: 10px;
    font-weight: bolder;
  }

  .closed {
    align-self: center;
  }

  .overview {
      align-self: center;
      padding-top: 70px;
  }

  .detail-head {
      border-radius: 50px;
      height: 35px;
      line-height: 35px;
      width: 130px;
      display: inline-block;
      font-size: 20;
      font-weight: 800;
      margin-bottom: 10px;
  }

  .lunch-head {
      background-color: #FED624;
      color: black;
  }

  .dinner-head {
      background-color: black;
      color: white;
  }

  .lunch-container {
    display: flex;
    flex-direction: column;
    overflow: auto;
    background: #FED624;
    color: #000;
    max-height: 100px;
    font-weight: bold;
    border-radius: 20px;
    padding: 10px;
    box-shadow: 0 3px 3px 0px rgba(0, 0, 0, 0.15);
  }

  .dinner-container {
    display: flex;
    flex-direction: column;
    overflow: auto;
    background: #000;
    color: #fff;
    max-height: 100px;
    font-weight: bold;
    border-radius: 20px;
    padding: 10px;
    box-shadow: 0 3px 3px 0px rgba(0, 0, 0, 0.15);
  }

  .truck-names {
    overflow: auto;
  }

  .truck-name {
      padding-left: 1.2em;
      text-indent: -1.2em;
      text-align: left;
      margin-left: 10px;
  }

  .truck-name::before {
    left: 0;
    right: 0;
    content: '- ';
    border-radius: 50%;
    width: 5px;
    height: 5px;
  }

  .detail {
    display: none;
}

  .date-label {
    padding: 10px;
    font-size: 16;
      .day-name {
        font-size: 34px;
        font-weight: 600;
        margin-top: 10px;
      }
  }
  }
}

@media (max-width: 600px) {
  .week-container {
    flex-direction: column;
    .widget-day{
      width: 100%;
      max-width: none;
    }
    .widget-day .lunch-container {
      margin-bottom: 2em;
      max-height: 200px;
    }
    .widget-day .dinner-container {
      max-height: 200px;
    }
  }

  .truck-name {
    width: 100%;
    font-size: 1.5rem;
  }


}