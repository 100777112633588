@import "_variables";


.login-button {
  width: 100%;
  height: 30px;
  font-size: 14px;
  border: 1px solid gainsboro;
  background-color: gainsboro;
  margin-bottom: 20px;
}

.login-button:hover {
  cursor: pointer;
}

.chevron-left {
  width: 30px;
  height: 30px;
  background-image: url('/img/Left-Arrow.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 40% auto;
  &.black {
    background-image: url('/img/leftarrowblack.png');
    margin-left: auto;
  }
}

.chevron-right {
  width: 30px;
  height: 30px;
  background-image: url('/img/Right-Arrow.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 40% auto;
  &.black {
    background-image: url('/img/rightarrowblack.png');
    margin-right: auto;
  }
}

.book-button {
  border-radius: 25px;
  color: $yellow;
  font-size: 16px;
  background: $white;
  border: solid $yellow 2px;
  text-decoration: none;
  text-align: center;
  padding: 5px;
  width: 100%;
  display: inline-block;
  min-height: 30px;
  max-height: 30px;
}

.book-button:hover {
  text-decoration: none;
  cursor: pointer;
}

.unbook-button {
  border-radius: 25px;
  color: $white;
  font-size: 16px;
  background: $red;
  border: solid $red 2px;
  text-decoration: none;
  text-align: center;
  padding: 5px;
  width: 100%;

  &-admin {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid $red;
    background: $red;
    background-image: url('/img/close_icon@2x.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 55% auto;
  }
}

.unbook-button:hover {
  text-decoration: none;
  cursor: pointer;
}

.unbook-button-admin:hover {
  text-decoration: none;
  cursor: pointer;
}

.close-button {
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1.5px solid $very-dark-gray;
  background-image: url('/img/close_icon@2x.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 55% auto;
}

.close-button:hover {
  cursor: pointer;
}

.modal-button {
  height: 40px;
  font-size: 14px;
  border: 1px solid gainsboro;
  background-color: gainsboro;
}

.modal-button:hover {
  cursor: pointer;
}

.done-button {
  margin-top: 30px;
  height: 30px;
  font-size: 14px;
  border: 1px solid gainsboro;
  background-color: gainsboro;
}
.done-button:hover {
  cursor: pointer;
}

.link-button {
  text-decoration: underline;
}

.save-button {
  border-radius: 6px;
  color: $white;
  font-size: 16px;
  background: $yellow;
  border: solid $yellow 2px;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  padding: 5px;
  min-width: 150px;
  display: inline-block;
  min-height: 30px;
  max-height: 30px;
}

.save-button:hover {
  cursor: pointer;
}

.delete-button {
  border-radius: 6px;
  color: $white;
  font-size: 16px;
  background: $red;
  border: solid $red 2px;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  padding: 5px;
  min-width: 150px;
  display: inline-block;
  min-height: 30px;
  max-height: 30px;
}

.delete-button:hover {
  cursor: pointer;
}

.plus-button {
  height: 30px;
  display: flex;
  align-items: center;
  color: $yellow;
  font-size: 16px;
  .plus {
    margin-right: 6px;
    height: 30px;
    width: 30px;
    background-image: url('/img/Plus@2x.png');
    background-size: cover;
    cursor: pointer;
  }
}

.plus-button:hover {
  cursor: pointer;
}