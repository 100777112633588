/*=============================================*\
           ¡HELPER CLASSES AND MIXINS
\*=============================================*/
@import "_variables";


/**
 * Mixins
 */
@mixin gridMachine($numPerRow, $margin) {
    width: ((100% - (($numPerRow - 1) * $margin)) / $numPerRow);
    margin-right: $margin;
    &:nth-child(#{$numPerRow}n) {
        margin-right: 0;
    }
}

@mixin animation($str) {
    -webkit-animation: #{$str};
       -moz-animation:  #{$str};
        -ms-animation:  #{$str};
         -o-animation:  #{$str};
            animation:  #{$str};
}

@mixin borderBox() {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}

@mixin transition($str) {
  -webkit-transition: #{$str};
     -moz-transition: #{$str};
      -ms-transition: #{$str};
       -o-transition: #{$str};
          transition: #{$str};
}

@mixin transform($str) {
  -webkit-transform: #{$str};
     -moz-transform: #{$str};
      -ms-transform: #{$str};
       -o-transform: #{$str};
          transform: #{$str};
}

// can i go from django admin -> angular -> css -> this function?
@function set-background($color) {
    @if(lightness($color) > 50) {
        @return #000000;
    }
    @else {
        @return #ffffff;
    }
}

@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
      @content;
  }
  @-moz-keyframes #{$animation-name} {
      @content;
  }
  @-ms-keyframes #{$animation-name} {
      @content;
  }
  @-o-keyframes #{$animation-name} {
      @content;
  }
  @keyframes #{$animation-name} {
      @content;
  }
}

/**
 * Displays and positioning
 */

.inline-block { display: inline-block !important; }
.block        { display: block !important; }
.relative     { position: relative !important; }
.hidden       { display: none !important }
.weak-hidden  { display: none }
.flex  {
  display: flex;
  &-column {
    flex-direction: column;
  }
}
.no-vis       { visibility: hidden !important }
.border-box   { @include borderBox(); }

@media screen and (max-width: breakpoint(tablet-lg)) {
  .on-desktop { display: none; }
}


@media screen and (min-width: breakpoint(tablet-lg) + 1) {
  .not-desktop { display: none; }
}

.transform-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

/**
 * Cursors
 */

.cursor-pointer { cursor: pointer !important; }
.pointer { cursor: pointer !important; }

/**
 * Widths / heights
 */

.full-width  { width: 100% !important; }
.full-height { height: 100% !important; }

/**
 * Text
 */

.text-center  { text-align: center !important; }
.text-left    { text-align: left !important; }
.text-right   { text-align: right !important; }
.no-indent    { text-indent: 0px !important; }

/**
 * Add/remove margins
 */
.push-double          { margin:       $double-spacing-unit!important; }
.push-double--top     { margin-top:   $double-spacing-unit!important; }
.push-double--right   { margin-right: $double-spacing-unit!important; }
.push-double--bottom  { margin-bottom:$double-spacing-unit!important; }
.push-double--left    { margin-left:  $double-spacing-unit!important; }
.push-double--ends    { margin-top:   $double-spacing-unit!important; margin-bottom:$double-spacing-unit!important; }
.push-double--sides   { margin-right: $double-spacing-unit!important; margin-left:  $double-spacing-unit!important; }

.push           { margin:       $base-spacing-unit!important; }
.push--top      { margin-top:   $base-spacing-unit!important; }
.push--right    { margin-right: $base-spacing-unit!important; }
.push--bottom   { margin-bottom:$base-spacing-unit!important; }
.push--left     { margin-left:  $base-spacing-unit!important; }
.push--ends     { margin-top:   $base-spacing-unit!important; margin-bottom:$base-spacing-unit!important; }
.push--sides    { margin-right: $base-spacing-unit!important; margin-left:  $base-spacing-unit!important; }

.push-half          { margin:       $half-spacing-unit!important; }
.push-half--top     { margin-top:   $half-spacing-unit!important; }
.push-half--right   { margin-right: $half-spacing-unit!important; }
.push-half--bottom  { margin-bottom:$half-spacing-unit!important; }
.push-half--left    { margin-left:  $half-spacing-unit!important; }
.push-half--ends    { margin-top:   $half-spacing-unit!important; margin-bottom:$half-spacing-unit!important; }
.push-half--sides   { margin-right: $half-spacing-unit!important; margin-left:  $half-spacing-unit!important; }

.push-part          { margin:       15px!important; }
.push-part--top     { margin-top:   15px!important; }
.push-part--right   { margin-right: 15px!important; }
.push-part--bottom  { margin-bottom:15px!important; }
.push-part--left    { margin-left:  15px!important; }
.push-part--ends    { margin-top:   15px!important; margin-bottom:15px!important; }
.push-part--sides   { margin-right: 15px!important; margin-left:  15px!important; }

.push-tiny          { margin:       5px!important; }
.push-tiny--top     { margin-top:   5px!important; }
.push-tiny--right   { margin-right: 5px!important; }
.push-tiny--bottom  { margin-bottom:5px!important; }
.push-tiny--left    { margin-left:  5px!important; }
.push-tiny--ends    { margin-top:   5px!important; margin-bottom:5px!important; }
.push-tiny--sides   { margin-right: 5px!important; margin-left:  5px!important; }

.push-quarter--top        { margin-top:   $quarter-spacing-unit!important; }
.push-quarter--bottom     { margin-bottom:   $quarter-spacing-unit!important; }

.flush          { margin:       0!important; }
.flush--top     { margin-top:   0!important; }
.flush--right   { margin-right: 0!important; }
.flush--bottom  { margin-bottom:0!important; }
.flush--left    { margin-left:  0!important; }
.flush--ends    { margin-top:   0!important; margin-bottom:0!important; }
.flush--sides   { margin-right: 0!important; margin-left:  0!important; }

.complete-flush          { margin:       0!important; padding: 0!important; }
.complete-flush--top     { margin-top:   0!important; padding-top: 0!important; }
.complete-flush--right   { margin-right: 0!important; padding-right: 0!important; }
.complete-flush--bottom  { margin-bottom:0!important; padding-bottom: 0!important; }
.complete-flush--left    { margin-left:  0!important; padding-left: 0!important; }
.complete-flush--ends    { margin-top:   0!important; margin-bottom:0!important; padding-bottom: 0!important; padding-top: 0!important; }
.complete-flush--sides   { margin-right: 0!important; margin-left:  0!important; padding-left: 0!important; padding-right: 0!important; }

/**
 * Add/remove paddings
 */
.pad           { padding:       $base-spacing-unit!important; }
.pad--top      { padding-top:   $base-spacing-unit!important; }
.pad--right    { padding-right: $base-spacing-unit!important; }
.pad--bottom   { padding-bottom:$base-spacing-unit!important; }
.pad--left     { padding-left:  $base-spacing-unit!important; }
.pad--ends     { padding-top:   $base-spacing-unit!important; padding-bottom:$base-spacing-unit!important; }
.pad--sides    { padding-right: $base-spacing-unit!important; padding-left:  $base-spacing-unit!important; }

.pad-double          { padding:       $double-spacing-unit!important; }
.pad-double--top     { padding-top:   $double-spacing-unit!important; }
.pad-double--right   { padding-right: $double-spacing-unit!important; }
.pad-double--bottom  { padding-bottom:$double-spacing-unit!important; }
.pad-double--left    { padding-left:  $double-spacing-unit!important; }
.pad-double--ends    { padding-top:   $double-spacing-unit!important; padding-bottom:$double-spacing-unit!important; }
.pad-double--sides   { padding-right: $double-spacing-unit!important; padding-left:  $double-spacing-unit!important; }

.pad-half           { padding:       $half-spacing-unit!important; }
.pad-half--top      { padding-top:   $half-spacing-unit!important; }
.pad-half--right    { padding-right: $half-spacing-unit!important; }
.pad-half--bottom   { padding-bottom:$half-spacing-unit!important; }
.pad-half--left     { padding-left:  $half-spacing-unit!important; }
.pad-half--ends     { padding-top:   $half-spacing-unit!important; padding-bottom:$half-spacing-unit!important; }
.pad-half--sides    { padding-right: $half-spacing-unit!important; padding-left:  $half-spacing-unit!important; }

.pad-quarter--bottom     { padding-bottom:   $quarter-spacing-unit!important; }
.pad-quarter--top     { padding-top:   $quarter-spacing-unit!important; }
.pad-quarter--right    { padding-right: $quarter-spacing-unit!important; }
.pad-quarter--left     { padding-left:  $quarter-spacing-unit!important; }

.pad-eighth--bottom     { padding-bottom:   $eighth-spacing-unit!important; }
.pad-eighth--top     { padding-top:   $eighth-spacing-unit!important; }
.pad-eighth--right    { padding-right: $eighth-spacing-unit!important; }
.pad-eighth--left     { padding-left:  $eighth-spacing-unit!important; }
/**
* Floats
*/
.none            { float: none !important; }
.pull--right     { float: right !important; }
.pull--left      { float: left !important;  }
.clear           { display: block; float: none; clear: both; }
.lk-clear:after {
  content: '';
  display: block;
  float: none;
  clear: both;
}

/**
* Centering elements
*/
.center--vertical          { vertical-align: middle; }
.center--vertical-single   { position: relative; top: 50%; transform: translateY(-50%); }
.center--horizontal        { margin: 0 auto; display: block; }
.text--center              { text-align: center !important; }
.text--left                { text-align: left !important; }
.text--right               { text-align: right !important; }
.divide--vertical          { height: 100%; width: 3px; }
.scroll-y                  { overflow-y: scroll; }
.lk-squeeze {
    margin: 0 auto;
    max-width: $squeeze-width;
    padding: 0 10px;
    &-lg {
        margin: 0 auto;
        max-width: $squeeze-lg;
        padding: 0 10px;
    }
}

// Text
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}