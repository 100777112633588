.container-wrapper {
  font-family: 'Raleway', sans-serif;
}

.bold {
  font-weight: 600 !important;
}

.warning {
  font-weight: 600;
  color: red;
}

.success {
  font-weight: 600;
  color: #279934;
}

.link {
  text-decoration: underline;
}

.center {
  text-align: center;
}
