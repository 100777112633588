@import "vendor/_timepicker";
@import "vendor/_datepicker";
@import "vendor/_reactselect";

.admin-container {
  max-width: 1200px;
  min-width: 600px;
  padding: 0 10px;
  margin: auto;
  height: 100%;
  .back {
    margin: 10px 0;
    background-image: url('/static/img/back-arrow-2x.png');
    background-repeat: no-repeat;
    background-size: 10px;
    padding-left: 15px;
    background-position: left center;
  }
  .heading {
    text-align: center;
    font-size: 16pt;
    font-weight: 600;
  }
}

.table-container {
  background: $off-white;
  border: $light-gray 1px solid;
  padding: 25px;
  width: 100%;

  table {
    width: 100%;
    border-collapse: collapse;  
  }
  td, th {
    text-align: left;
  }
}

.row-container {
  background: $white;
  height: 76px;
  border-bottom: 6px solid $off-white;
  .truck-image {
    margin: 0 5px;
    height: 50px;
    width: 50px;
    border-radius: 100%;
    overflow: hidden;
    text-align: center;
    img {
      height: 50px;
      width: 50px;
    }
  }

  .truncate {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.verified-icon-wrapper {
  text-align: center;
  display: inline-block;
  width: 40px;
  img {
    height: 30px;
    width: auto;
  }
}

.row {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.row-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.row-spaced {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.col-1-2 {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.white-background {
  background: $white;
}

.rrule-container {
  border: 1px solid $light-gray;
  padding: 10px;
  hr {
    display: none;
  }
  // input.form-control:not([type='checkbox']) {
  //   width: 100%;
  //   height: 36px;
  //   line-height: 30px;
  //   font-size: 12px;
  //   margin: 5px 0;
  //   padding: 1px 1px 1px 10px;
  // }
  .rrule-form {
    margin-top: 15px; 
    margin-bottom: 15px; 
    select {
      margin-left: 10px;
    }
    .repetitions {
      input:not([type='checkbox']) {
        width: 30px;
        text-align: center;
        // padding: 0;
        margin-left: 5px;
        margin-right: 5px;
      }
    }
    .end-after {
      width: 30px;
      text-align: center;
      // padding: 0;
      margin-left: 5px;
      margin-right: 5px;
    }
  }
}

.large-text-input {
  padding: 10px;
  min-height: 150px;
  width: 100%;
  border: 1px solid $light-gray;
}

.remove-icon {
  height: 20px;
  width: auto;
  cursor: pointer;
}

.rule-info {
  line-height: 20px;
  margin-left: 10px;
}

.instance {
  margin-top: 15px;
  .date {
    font-size: 12pt;
    // font-weight: bold;
    margin: 5px 0;
  }
  .edit-container {
    background-color: white;
    border-radius: 5px;
    padding: 15px;
  }
  .edit-label {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.event-detail-form {
  background-color: white;
  padding: 5px;
}

.menu-item {
  width: 50% !important;
  margin-left: 10px !important;
}
.remove-menu-item {
  vertical-align: middle;
}

.event-detail-modal {
  background-color: white;
  margin: auto;
  width: 400px;
  max-height: 200px;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  border: 3px solid gray;
  border-radius: 10px;
  padding: 15px;
  .modal-container {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    border: none;
    height: 100%;
  }
  .close-modal {
    background-color: #A80000;
    cursor: pointer;
    border-radius: 3px;
    margin-top: auto;
    margin-left: auto;
    margin-right: auto;
    padding: 5px;
    color: white;
  }
}