/*===========================================================================*\
                                    ¡VARIABLES
\*===========================================================================*/
$configs: (
  header: 100px,
  footer: 91px,
  sidebar: 275px
);

/**
* Colors map
*/
$colors: (
  blue-100: rgb(1,170,247),
);

$very-dark-gray: #3D3D3D;
$dark-gray: #6F6F6F;
$light-gray: #D8D8D8;
$off-white: #F7F7F7;
$yellow: #FFC500;
$green: #279934;
$red: #CF4E4E;
$white: #FFFFFF;

$breakpoints: (
  phone:      450px,
  phone-lg:   650px,
  tablet:     767px,
  tablet-lg:  992px,
  desktop-lg: 1024px,
  desktop-xl: 1400px
);

/*------------------------------------*\
           Helper functions
\*------------------------------------*/
/**
* so we don't always have to
* type 'map-get'
*/
/* background: color(color-100); */
@function config($key) {
  @if map-has-key($configs, $key) {
    @return map-get($configs, $key);
  }
  @warn "Unknown #{$key} in configs map";
  @return null;
}

@function color($key) {
  @if map-has-key($colors, $key) {
    @return map-get($colors, $key);
  }
  @warn "Unknown #{$key} in colors map";
  @return null;
}

/* breakpoint(phone); */
@function breakpoint($key) {
  @if map-has-key($breakpoints, $key) {
    @return map-get($breakpoints, $key);
  }
  @warn "Unknown #{$key} in breakpoints map";
  @return null;
}

/*------------------------------------*\
          ¡SPACING AND ¡SIZES
\*------------------------------------*/
$base-spacing-unit: 35px;
$border-width:      6px;
$default-font-size: 16px;
$double-spacing-unit: ($base-spacing-unit * 2);
$footer-height:     91px;
$half-spacing-unit: calc($base-spacing-unit / 2);
$header-height:     95px;
$quarter-spacing-unit: calc($base-spacing-unit / 4);
$eighth-spacing-unit: calc($base-spacing-unit / 8);
$row-height:        64px;
$side-nav-width:    220px;
$side-nav-width-tblt: 170px;
$squeeze-lg:        930px;
$squeeze-width:     860px;
$top-nav-height:    35px;

/*------------------------------------*\
                  ¡MISC
\*------------------------------------*/
