@media screen and (max-width: 810px) {
  .tabs-row {
    .tabs {
      .tab {
        height: 45px !important;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .tabs-row {
    .tabs-right {
      margin-left: 0px !important;
      overflow-x: auto;
    }
  }
  .logo-wrapper {
    display: none !important;
  }
  .filter-row {
    flex-direction: column;
    height: max-content !important;
    &-sub {
      min-height: 250px;
      height: max-content;
      .table-holder {
        height: 100%;
      }
    }
    .filter {
      width: 100% !important;
      display: block !important;
      .scroll-wrapper {
        overflow-y: auto !important;
        max-height: 400px;
      }
    }
    .event-detail {
      min-height: 250px;
      display: block !important;
      height: max-content;
    }
  }
  .calendar-row {
    flex-direction: column;
    background-color: #6F6F6F;
    height: auto !important;
    .calendar {
      margin: auto;
      min-width: 300px;
      width: 75% !important;
    }
    .week {
      width: 100% !important;
      overflow-x: scroll;
      .day {
        min-width: 100px;
        min-height: 150px;
        max-height: 400px;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .filter-row {
    &-sub {
      flex-direction: column !important;
    }
    .event-detail {
      width: 100% !important;
    }
    .rule {
      width: 100% !important;
    }
  }
}