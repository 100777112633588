@media screen and (max-width: 1000px) {
  .week-container {
	  overflow: hidden;
	  .widget-day {
	  	min-width: 60%;
		  &.selected {
		  	flex-grow: 100;
		  	max-width: 60%;
		  }
		}
	}
}

@media screen and (max-width: 600px) {
	.widget-subheading {
		box-shadow: none;
	}
  .week-container {
  	background: none;
	  .widget-day {
	  	&.selected {
		  	background: none;
		  }
	  	.date-label {
	  		text-align: left;
	      width: 100%;
	      height: auto;
	      position: relative;
	      padding: 0;
	      .day-name {
		      writing-mode: unset;
		      margin-top: 0;
		      font-size: 20pt;
		      margin-left: 0px;
	      }
		  }
	  	.content {
				display: block;
				margin-right: auto;
				margin-left: 0;
				text-align: left;
				margin-top: 15px;
				&.detailed {
					width: 100%;
				}
		  	.detail {
		      display: block;
		      width: 100%;
		      float: none;
		      .truck-name {
			      margin-left: 15px;
			    }
		    }
		    .detail-head {
					text-align: center;
		    }
		    .arrow {
					height: 100%;
					display: inline-block;
					vertical-align: middle;
					margin-top: -4px;
					margin-left: 10px;
					transition: transform 0.3s;
					&.selected {
						transform: rotate(90deg);
					}
		    }
	  	}
		}
	}
}